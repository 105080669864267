<template >
  <div class="loginFormCard fullHeightViewport">
    <div class="py-6 d-flex flex-column">
      <div class="d-flex justify-start">
        <v-img
          max-width="120px"
          class="loginFormCard__logo"
          contain
          :src="logoPath"
        ></v-img>
      </div>
      <div>
        <h1 class="heading primary--darken5 mb-3">{{ recoveryData.title }}</h1>
        <span  class="heading-sub body-2 mb-0">{{ recoveryData.description }}</span>
      </div>
    </div>
    <form @keyup.enter="logIn">
      <v-sheet class="error">
      </v-sheet>
      <slot></slot>
      <div class="py-4" v-if="recoveryData.detail">
        <p class="caption detail-text">{{ recoveryData.detail }}</p>
      </div>
      <div class="py-3">
        <vue-recaptcha :sitekey="siteKey" loadRecaptchaScript @verify="onVerify"
          @expired="onExpired"></vue-recaptcha>
      </div>
      <div class="d-flex justify-space-between pt-3">
        <v-btn @click="goBack" class="px-6" text rounded large>Atrás</v-btn>
        <v-btn @click="sendSecurityCode" color="primary" class="px-6" :loading="loading" rounded large :disabled="!(canGoNextStep && validCaptcha)">Siguiente</v-btn>
      </div>
    </form>
  </div>
  
  </template>
  
  <style lang="sass">
      @media (max-width: 768px)
          .loginFormCard
              & .display-1
                  margin-top: 1rem
              & form
                  & .detail-text
                      margin-bottom: 0 !important
  </style>
  
  <script>
  import VueRecaptcha from 'vue-recaptcha'
  
  export default {
    name: 'RecoveryForm',
    components: {
      VueRecaptcha
    },
    props: {
      loading: Boolean,
      logoPath: String,
      recoveryData: Object,
      canGoNextStep: Boolean
    },
    data () {
      return {
        validCaptcha: false,
      }
    },
    computed: {
      siteKey() {
        if (this.$isIPP) return '6LcOzcIUAAAAAJkIG1uFST_T2T_NOmRPrBylyQWC'
        else if (this.$isTeclab) return '6Le3p9AbAAAAAALXOsWRMmiOhllexcDsnlAOK9R2'
        else return ''
      }
    },
    methods: {
      sendSecurityCode () {
        this.$trackEvent('Login', 'Clk_BtnNext_RecContr', 'Rec_Contr');
        this.$emit('sendSecurityCode');
      },
      goBack () {
        this.$trackEvent('Login', 'Clk_BtnBack_RecContr', 'Rec_Contr');
        this.$router.go(-1)
      },
      onVerify () {
        this.validCaptcha = true;
      },
      onExpired () {
        this.validCaptcha = false;
      },
    }
  };
  </script>
  <style scoped lang="sass">
  .heading 
    font-size: 33px 
    margin-top: 30px
  .heading-sub 
    font-size: 12px !important
  @media (max-width: 500px)
    .heading
      font-size: 19px
  
  
      </style>