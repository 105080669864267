import Constants from '@/plugins/constants'
import userService from '@/plugins/user.service'
import app from '@/main'
import jwt from 'jsonwebtoken';

//import Configuration from '../../constants/configuration';

export default {
  async initData ({ state, commit,getters }) {
    try {
      /*if (Configuration.value('theme') == 'teclab2') {
        const isKlarway = window.navigator.userAgent.search('klarway') == -1 ? false : true //  verifica en el objeto window.navigator.userAgent  que proctoring se esta usando , buscando en ese texto la palabra klarway y si no lo encuentra devuelve -1(false) sino retorna true
        const student = JSON.parse(localStorage.getItem('teclab_student'))
        const legajo = student.carreras && student.carreras[0] && student.carreras[0].idAlumnoCarrera ? student.carreras[0].idAlumnoCarrera : ''

        const userData = {
          id: student.alumno_id,
          userData: {
            // avatar: `${Constants.URL_DATA_SERVER}/users/${student.alumno_id}/avatar`,
            avatar: 'https://api.ipp.cl/gw/core/users/173/avatar',
            // avatar: null,
            name: student.nombres,
            lastname: student.apellido
          },
          legajos: [
            {
              status: 'Cursando',
              studentId: student.alumno_id,
              legajo,
            }
          ],
          roles: [
            {
              name: 'Alumno',
              applications: [
                {
                  name: 'Exámenes',
                  modules: [
                    {
                      id: 1,
                      name: 'Exámenes virtuales',
                      description: 'Module virtual exams',
                      path: '/exams-available',
                      order: 1,
                      status: true,
                      imagePath: 'mdi-laptop-mac',
                      version: '0.1.1',
                      code: null,
                      parent: null,
                      allowed: null,
                      application: {
                        id: 1,
                        name: null,
                        domain: null,
                        description: null,
                        icon: null,
                        status: null,
                        modules: null
                      },
                      permissions: [
                        {
                          id: 2,
                          name: 'CREATE',
                          description: 'Create final exam',
                          status: true,
                          module: null,
                          rols: null
                        }, {
                          id: 1,
                          name: 'READ',
                          description: 'Read available exams',
                          status: true,
                          module: null,
                          rols: null
                        }, {
                          id: 3,
                          name: 'UPDATE',
                          description: 'Review final exam',
                          status: true,
                          module: null,
                          rols: null
                        }
                      ],
                      children: null
                    }
                  ]
                }
              ]
            }
          ]
        }
        commit('SET_USERDATA', userData);
        commit('SET_LOGGED_USERDATA', userData);
        commit('SET_LOGINIMAGE');
        commit('SET_USERDEFAULT');
        commit('SET_LOGOIMAGE');
        commit('SET_KLARWAY_PROCTORING', isKlarway);
      } else {*/
        const asset = getters.getAssetsURL;
        const userData = await userService.getUserData();
        userData.userData.avatar = `${asset}/${userData.userData.avatar}`;

        await commit('SET_USER_PERMISSIONS', userData);
        const student = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
        const jwtDecoded = jwt.decode(JSON.parse(localStorage.getItem('access_token')));
        state.isAdmin = jwtDecoded.isAdvisor;

        if (state.isAdmin && student) commit('SET_USERDATA', student);
        else if (!state.isAdmin) {
          commit('SET_USERDATA', userData);
          window.localStorage.setItem('alumnoSeleccionado', JSON.stringify(userData));
        }
      
        const isKlarway = window.navigator.userAgent.search('klarway') == -1 ? false : true //  verifica en el objeto window.navigator.userAgent  que proctoring se esta usando , buscando en ese texto la palabra klarway y si no lo encuentra devuelve -1(false) sino retorna true

        commit('SET_LOGGED_USERDATA', userData);
        commit('SET_LOGINIMAGE');
        commit('SET_USERDEFAULT');
        commit('SET_LOGOIMAGE');
        commit('SET_KLARWAY_PROCTORING', isKlarway);
      //}
     
    } catch (error) {
      app.$router.replace('/logout');
    }
  },
  async setStudentData({ commit, getters }, userName) {
    const asset = getters.getAssetsURL;
    if (userName) {
      const userData = await userService.getUserData(userName);
      userData.userData.avatar = `${asset}/${userData.userData.avatar}`;
      window.localStorage.setItem('alumnoSeleccionado', JSON.stringify(userData));
      commit('SET_USERDATA', userData);
    } else {
      window.localStorage.setItem('alumnoSeleccionado', null);
      commit('SET_USERDATA', null);
    }
  },
  setUserData ({ commit }, userData) {
    if (userData.userData) userData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${userData.id}/avatar`
    commit('SET_USERDATA', userData)
  },
  loadImages ({ commit }) {
    commit('SET_LOGINIMAGE');
    commit('SET_USERDEFAULT');
    commit('SET_LOGOIMAGE')
  },
  initHash({ commit }, hash){ commit('SET_HASH', hash)},
  closePopUp ({ commit }) { commit('CLOSE_POPUP') },
  openPopUp ({ commit }, data) { commit('OPEN_POPUP', data) },
  togglePopUp ({ commit }, data) { commit('TOGGLE_POPUP', data) },
  updateAvatar ({ commit }) { commit('UPDATE_AVATAR') },
  clearUserData ({ commit }) { commit('CLEAR_USERDATA') },
  setLoginImage ({ commit }) { commit('SET_LOGINIMAGE') },
  setUserDefault ({ commit }) { commit('SET_USERDEFAULT') },
  setLogoImage ({ commit }) { commit('SET_LOGOIMAGE') },
  
  setLoadingDrawer ({commit}, data) { commit('SET_LOADING_DRAWER', data) },
  increaseLoading({commit}) { commit('INCREASE_LOADING') },
  decreaseLoading({commit}) { commit('DECREASE_LOADING') },

  userPermissions({state}) { return state.commons.permissions },

}
